<!--
 * @Description: 回访单详情
 * @Author: ChenXueLin
 * @Date: 2021-10-14 17:34:47
 * @LastEditTime: 2022-10-18 09:21:41
 * @LastEditors: ChenXueLin
-->
<!-- 基本信息 -->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 表格 start -->
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 头部状态 -->
          <detail-title-content
            :btnType="btnType"
            :btnText="'回访单状态:' + basicInfo.reviewStatus"
            :showTime="false"
          >
            <template v-slot:rightButtons>
              <el-button
                type="primary"
                @click="closeTask('回访单')"
                v-if="basicInfo.reviewStatusCode == 2"
                >关闭回访单</el-button
              >
            </template>
          </detail-title-content>
          <!-- 基本信息 start -->
          <div class="baseInfo-box">
            <div class="edit-title">
              基本信息
            </div>
            <el-form label-width="140px" class="editForm">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="回访单号">
                    {{ basicInfo.workNo }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="回访单类型">
                    {{ basicInfo.workType }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="回访类型">
                    {{ basicInfo.reviewType }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="创建人">
                    {{ basicInfo.createdBy }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="创建时间">
                    {{ basicInfo.createdTimeStr }}
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <!-- 基本信息 end -->
          <!-- 任务项 start -->
          <div class="task-list-content">
            <detail-table-title @resize="handleTableTitleResize">
              <template v-slot:title>
                任务项
              </template>
              <template v-slot:btns>
                <el-button type="primary" @click="handleChange"
                  >指派处理人</el-button
                >
              </template>
              <template v-slot:searchForm>
                <el-form
                  class="search-list"
                  ref="searchForm"
                  :model="searchForm"
                >
                  <el-form-item class="search-item--1" prop="corpName">
                    <el-input
                      placeholder="客户名称"
                      title="客户名称"
                      v-model="searchForm.corpName"
                    ></el-input>
                  </el-form-item>
                  <el-form-item class="search-item--1" prop="itemStatus">
                    <e6-vr-select
                      v-model="searchForm.itemStatus"
                      :data="statusList"
                      placeholder="任务项状态"
                      title="任务项状态"
                      clearable
                    ></e6-vr-select>
                  </el-form-item>
                  <el-form-item class="search-item--1" prop="revisitStatus">
                    <e6-vr-select
                      v-model="searchForm.revisitStatus"
                      :data="visiteStatus"
                      placeholder="回访状态"
                      title="回访状态"
                      clearable
                    ></e6-vr-select>
                  </el-form-item>
                  <el-form-item class="search-item--buttons">
                    <el-button type="primary" @click="handleSearch"
                      >查询</el-button
                    >
                    <el-button class="reset" @click="handleReset"
                      >重置</el-button
                    >
                  </el-form-item>
                </el-form>
              </template>
            </detail-table-title>
            <!-- 表格 -->
            <el-table
              border
              height="525px"
              :data="tableData"
              highlight-current-row
              class="elTable"
              ref="multipleTable"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                type="selection"
                width="40"
                :selectable="selectable"
              ></el-table-column>
              <el-table-column
                show-overflow-tooltip
                v-for="(column, index) in columnData"
                :key="index"
                :prop="column.fieldName"
                :label="column.fieldLabel"
                :min-width="column.width"
                :fixed="column.fixed"
                :align="column.align"
                header-align="center"
              >
                <template slot-scope="{ row }">
                  <span v-if="column.fieldName === 'contactPhone'">
                    <el-button type="text" @click="handleCallPhone(row)">
                      {{ row[column.fieldName] }}
                    </el-button>
                  </span>
                  <span
                    v-else-if="column.fieldName === 'contactName'"
                    @click="clickContant(row)"
                  >
                    <el-button type="text">
                      {{ row[column.fieldName] }}
                    </el-button>
                    <i class="e6-icon-arrow-b_fill" style="color:#46BFEA"></i>
                  </span>
                  <span v-else>{{ row[column.fieldName] }}</span>
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="操作"
                width="120"
              >
                <template slot-scope="scope">
                  <e6-td-operate
                    :data="getOperateList(scope.row)"
                    @command="handleOperate($event, scope.row)"
                  ></e6-td-operate>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 start -->
            <section class="pagination-wrapper fixed-section">
              <el-pagination
                :page-size.sync="searchForm.pageSize"
                :current-page.sync="searchForm.pageIndex"
                :page-sizes="pageSizes"
                :layout="layout"
                :total="total"
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              ></el-pagination>
            </section>
            <!-- 分页 end -->
          </div>
          <!-- 任务项 end -->
          <!-- 进行回访弹框  -->
          <el-dialog
            v-dialogDrag
            title="进行回访"
            :visible="returnVisitVisible"
            width="450px"
            :close-on-click-modal="false"
            :element-loading-background="loadingBackground"
            :before-close="
              () => {
                handleClose('returnVisitVisible');
              }
            "
            custom-class="custom-dialog"
          >
            <div>
              <div
                class="question-content"
                v-for="(item, index) in questionList"
                :key="item.id"
              >
                <div :class="[{ required: item.required == 1 }, 'title']">
                  <div>{{ index + 1 }}、{{ item.questionContent }}</div>
                  <span class="count" v-if="index == 0"
                    >{{ hasWrite }}/{{ questionList.length }}</span
                  >
                </div>
                <div class="radio-content">
                  <el-radio-group v-model="item.answer">
                    <el-radio
                      :label="each"
                      v-for="each in item.answerAlter"
                      :key="each"
                      >{{ each }}</el-radio
                    >
                  </el-radio-group>
                </div>
                <div class="textarea-content">
                  <el-input
                    placeholder="备注"
                    type="textarea"
                    v-model="item.remark"
                    maxlength="100"
                  ></el-input>
                </div>
              </div>
            </div>
            <div class="dialog-footer" slot="footer">
              <el-button
                class="cancel"
                @click="handleClose('returnVisitVisible')"
                >取消</el-button
              >
              <el-button type="primary" @click="handleSubmit">确定</el-button>
            </div>
          </el-dialog>
          <!-- 回访结果弹框  start -->
          <el-dialog
            v-dialogDrag
            title="回访结果"
            :visible="resultVisible"
            width="450px"
            :close-on-click-modal="false"
            v-loading="dialogLoading"
            :element-loading-background="loadingBackground"
            :before-close="
              () => {
                handleClose('resultVisible');
              }
            "
            custom-class="custom-dialog"
          >
            <div
              class="result-content"
              v-for="(item, index) in resultList"
              :key="item.id"
            >
              <div class="title">
                {{ index + 1 }}、{{ item.questionContent }}
              </div>
              <div class="answer">答案选项：{{ item.answer }}</div>
              <div class="answer">备注：{{ item.remark }}</div>
            </div>
          </el-dialog>
          <!-- 指派处理人弹框 -->
          <el-dialog
            v-dialogDrag
            title="指派处理人"
            :visible="handleByVisible"
            width="450px"
            :close-on-click-modal="false"
            v-loading="dialogLoading"
            :element-loading-background="loadingBackground"
            :before-close="
              () => {
                handleClose('handleByVisible');
              }
            "
            custom-class="custom-dialog"
          >
            <el-form
              :model="handleByForm"
              ref="handleByForm"
              :rules="handleByFormRules"
            >
              <el-form-item label="处理人" prop="employeeId">
                <all-user-search
                  v-model="handleByForm.employeeId"
                  clear
                  placeholder="处理人"
                  title="处理人"
                  :propsConfig="{
                    id: 'employeeId',
                    label: 'employeeName'
                  }"
                  @change="changeEmployee"
                >
                </all-user-search>
              </el-form-item>
            </el-form>
            <div class="dialog-footer" slot="footer">
              <el-button class="cancel" @click="handleClose('handleByVisible')"
                >取消</el-button
              >
              <el-button type="primary" @click="confirmHandler">确定</el-button>
            </div>
          </el-dialog>
          <!-- 更换联系人 -->
          <el-dialog
            v-dialogDrag
            title="更换联系人"
            :visible="changeContantDialog"
            :close-on-click-modal="false"
            v-loading="dialogLoading"
            :element-loading-background="loadingBackground"
            :before-close="
              () => {
                handleClose('changeContantDialog');
              }
            "
            custom-class="custom-dialog"
          >
            <el-form
              class="search-list"
              ref="contactSearchForm"
              :model="contactSearchForm"
            >
              <el-form-item class="search-item--1" prop="contactNameOrPhone">
                <el-input
                  style="width:200px"
                  v-model="contactSearchForm.contactNameOrPhone"
                  placeholder="请输入联系人名称或电话搜索"
                  title="请输入联系人名称或电话搜索"
                ></el-input>
              </el-form-item>
              <el-form-item class="search-item--buttons">
                <el-button type="primary" @click="getContactList"
                  >查询</el-button
                >
              </el-form-item>
            </el-form>
            <el-table
              border
              :data="contactList"
              highlight-current-row
              ref="selectTable"
              height="470px"
              @row-click="confirmChange"
            >
              <el-table-column
                show-overflow-tooltip
                v-for="(column, index) in contactColumnData"
                :key="index"
                :prop="column.fieldName"
                :label="column.fieldLabel"
                :min-width="column.width"
                :fixed="column.fixed"
                :align="column.align"
                header-align="center"
              >
              </el-table-column>
            </el-table>
            <!-- <el-form
              :model="changeForm"
              ref="changeForm"
              :rules="changeFormRules"
            >
              <el-form-item label="联系人" prop="contactId">
                <e6-vr-select
                  v-model="changeForm.contactId"
                  :data="contactList"
                  :props="{
                    id: 'contactId',
                    label: 'contactName'
                  }"
                  placeholder="联系人"
                  title="联系人"
                  clearable
                ></e6-vr-select>
              </el-form-item>
            </el-form> -->
            <!-- <div class="dialog-footer" slot="footer">
              <el-button
                class="cancel"
                @click="handleClose('changeContantDialog')"
                >取消</el-button
              >
              <el-button type="primary" @click="confirmChange">确定</el-button>
            </div> -->
          </el-dialog>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import { printError } from "@/utils/util";
import allUserSearch from "@/components/allUserSearch";
import {
  // getAddressBook,
  getContactList,
  changeContact,
  getReviewInfo,
  getReviewService,
  handler,
  getVisiteResult,
  getListQuestions,
  submitQuestion,
  closeServiceTask,
  callPhone
} from "@/api";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import detailTitleContent from "@/components/detailTitleContent";
import taskDetail from "@/mixins/taskDetail";
import DetailTableTitle from "@/components/detailTableTitle.vue";
export default {
  name: "",
  data() {
    return {
      answer: "visitDetail",
      loading: false,
      dialogLoading: false,
      radio: "",
      columnData: [
        {
          fieldName: "itemId",
          display: true,
          fieldLabel: "工单ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "taskItemStatusStr",
          display: true,
          fieldLabel: "任务项状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "revisitStatusStr",
          display: true,
          fieldLabel: "回访状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "客户名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "contactName",
          display: true,
          fieldLabel: "联系人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "contactPhone",
          display: true,
          fieldLabel: "联系电话",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "currentName",
          display: true,
          fieldLabel: "当前处理人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "remark",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //场景变更服务单详情表头
      searchForm: {
        corpName: "",
        revisitStatus: "",
        itemStatus: "",
        pageSize: 20,
        pageIndex: 1
      },
      total: 0,
      basicInfo: {}, //
      tableData: [],
      statusList: [
        {
          id: 3,
          label: "已完成"
        },
        {
          id: 4,
          label: "进行中"
        },
        {
          id: 5,
          label: "已关闭"
        }
      ],
      visiteStatus: [
        {
          id: 1,
          label: "已回访"
        },
        {
          id: 2,
          label: "未回访成功"
        },
        {
          id: 3,
          label: "未回访"
        }
      ],
      /******进行回访*****/
      returnVisitVisible: false,
      questionList: [], //问题列表
      currentItemId: "", //当前回访的任务项id
      /******回访结果********/
      resultVisible: false,
      resultList: [], //回访结果
      /*****处理人*********/
      selColumnIds: [], //勾选的任务项
      handleByForm: {
        employeeId: "",
        employeeName: ""
      },
      handleByFormRules: {
        employeeId: [
          {
            required: true,
            message: "请选择处理人",
            trigger: ["blur", "change"]
          }
        ]
      },
      handleByVisible: false,
      /*****更换联系人*******/
      contactSearchForm: {
        contactNameOrPhone: ""
      },
      contactColumnData: [
        {
          fieldName: "contactName",
          display: true,
          fieldLabel: "联系人名称",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "contactTypeName",
          display: true,
          fieldLabel: "联系人职责",
          width: 140,
          disabled: false,
          fixed: false,
          sortable: false,
          align: "center"
        },
        {
          fieldName: "phoneList",
          display: true,
          fieldLabel: "电话/手机",
          width: 140,
          disabled: false,
          fixed: false,
          sortable: false,
          align: "center"
        }
      ],
      contactList: [], //联系人下拉框
      curRow: {},
      changeContantDialog: false,
      changeForm: { contactId: "" },
      changeFormRules: {
        contactId: [
          {
            required: true,
            message: "请选择联系人",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  mixins: [base, listPage, listPageReszie, taskDetail],
  computed: {
    btnType() {
      let value = "";
      if (this.basicInfo.reviewStatusCode == 1) {
        value = "yellowBtn";
      }
      if (this.basicInfo.reviewStatusCode == 2) {
        value = "blueBtn";
      }
      if (
        this.basicInfo.reviewStatusCode == 3 ||
        this.basicInfo.reviewStatusCode == 4
      ) {
        value = "greenBtn";
      }
      return value;
    },
    hasWrite() {
      let count = this.questionList.filter(item => {
        return item.answer || item.remark;
      }).length;
      return count;
    }
  },
  components: {
    detailTitleContent,
    DetailTableTitle,
    allUserSearch
  },
  watch: {},
  methods: {
    //点击电话 拨打
    handleCallPhone(row) {
      if (!Cookies.get("PhoneExt") || Cookies.get("PhoneExt") == "null") {
        this.$message.warning("您没有呼叫平台坐席号，无法外呼电话。");
        return;
      }
      this.$confirm("是否确认拨打此电话？", "拨打电话", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.callPhone(row.contactPhone, row.corpId, row.revisitId);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },

    async callPhone(phone, corpId, revisitId) {
      this.loading = true;
      try {
        let res = await callPhone({
          customerNumber: phone,
          revisitTypeCode: this.basicInfo.reviewTypeCode,
          revisitId,
          corpId,
          cno: Cookies.get("PhoneExt")
        });
        if (res.code === "OK") {
          this.$message.success("呼叫成功，请注意您的手机电话。");
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    handleSearch() {
      this.getReviewServiceReq();
    },
    //获取回访服务单详情
    async queryDetail() {
      try {
        this.loading = true;
        let res = await getReviewInfo({ workNo: this.workNo });
        this.basicInfo = res.data;
        this.getReviewServiceReq();
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取回访服务任务项
    async getReviewServiceReq() {
      try {
        let res = await getReviewService({
          ...this.searchForm,
          workId: this.basicInfo.workId
        });
        this.tableData = res.data.array;
        this.total = res.data.totalRecords;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取页面操作权限
    getOperateList(row) {
      // 页面按钮权限
      let operates;
      if (row.revisitStatus == 1) {
        //已完成
        operates = [
          {
            label: "回访结果",
            id: 2
          }
        ];
      }
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        //进行回访
        this.routerPush({
          name: "callCenter/workbench",
          query: {
            needQues: true,
            returnTypeCode: this.basicInfo.reviewTypeCode,
            revisitId: row.revisitId,
            corpId: row.corpId
          },
          params: {
            refresh: true
          }
        });
      }
      if (val.id == 2) {
        //查看回访结果
        this.resultVisible = true;
        this.getVisiteResultReq(row.itemId);
      }
    },
    //勾选数据
    handleSelectionChange(columns) {
      this.selColumnIds = columns.map(item => {
        return item.itemId;
      });
    },
    //禁止选中行
    selectable: function(row) {
      if (row.taskItemStatus != 3 && row.taskItemStatus != 5) {
        return true;
      }
    },
    //查看回访结果
    async getVisiteResultReq(itemId) {
      try {
        this.dialogLoading = true;
        let res = await getVisiteResult({ itemId });
        this.resultList = res.data.reviewVO;
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    /*******进行回访***********/
    //提交回访
    handleSubmit() {
      let questionList = this.questionList;
      if (
        questionList
          .filter(item => {
            return item.required;
          })
          .every(each => {
            return each.answer;
          })
      ) {
        this.submitQuestionReq();
      } else {
        this.$message.warning("有必填项未进行填写");
        return;
      }
    },
    //提交回访请求
    async submitQuestionReq() {
      try {
        this.dialogLoading = true;
        let res = await submitQuestion({
          reviewVO: this.questionList,
          itemId: this.currentItemId
        });
        if (res.code == "OK") {
          this.$message.success("提交成功");
          this.handleClose("returnVisitVisible");
          this.getReviewServiceReq();
          this.queryDetail();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //获取问题列表
    async getListQuestions() {
      try {
        this.loading = true;
        let res = await getListQuestions({
          returnTypeCode: this.basicInfo.reviewStatusCode,
          returnType: this.basicInfo.reviewType
        });
        let questionList = _.cloneDeep(res.data);
        questionList.map(item => {
          item.answerAlter = item.answerAlter.split(",");
          item.answer = "";
        });
        this.questionList = questionList;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    /******指派处理人*********/

    //点击指派处理人
    handleChange() {
      if (!this.selColumnIds.length) {
        this.$message.warning("请先勾选任务项");
        return;
      }
      this.handleByVisible = true;
    },
    //确定处理人
    confirmHandler() {
      this.$refs.handleByForm.validate(valid => {
        if (valid) {
          this.handlerReq();
        }
      });
    },
    changeEmployee(val, node) {
      this.handleByForm.employeeName = node.userName;
    },
    //指派处理人请求
    async handlerReq() {
      try {
        this.dialogLoading = true;
        let res = await handler({
          ...this.handleByForm,
          itemIdList: this.selColumnIds
        });
        if (res.code == "OK") {
          this.$message.success("指派成功");
          this.$refs.multipleTable.clearSelection();
          this.selColumnIds = [];
          this.handleByForm = {
            employeeId: ""
          };
          this.$refs.handleByForm.resetFields();
          this.handleClose("handleByVisible");
          this.queryDetail();
          this.getReviewServiceReq();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    /*******更换联系人********/
    //点击联系人
    clickContant(row) {
      this.curRow = row;
      this.changeContantDialog = true;
      this.getContactList();
    },
    //获取联系人下拉框
    async getContactList() {
      try {
        this.dialogLoading = true;
        let res = await getContactList({
          id: this.curRow.corpId,
          ...this.contactSearchForm
        });
        //客户名称下拉框
        let contactList = res.data;
        contactList.map(item => {
          item.contactTypeName = item.contactTypeName.join(";");
          item.phoneList = item.phoneList.join(";");
        });
        this.contactList = contactList;
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //确认修改联系人
    confirmChange(row) {
      // console.log(row);
      this.handleClose("changeContantDialog");
      this.changeContactReq(row);
      // this.$refs.changeForm.validate(valid => {
      //   if (valid) {
      //     this.changeContactReq();
      //   }
      // });
    },
    async changeContactReq(row) {
      try {
        this.dialogLoading = true;
        let promiseList = [
          changeContact({
            id: this.curRow.itemId,
            contactId: row.contactId,
            contactName: row.contactName,
            contactPhone: row.phone1 || row.phone2
          })
          // submitQuestion({
          //   revisitId: this.curRow.revisitId,
          //   revisitedManName: row.contactName,
          //   revisitedManNumber: row.phone1 || row.phone2
          // })
        ];
        // res2
        let [res1] = await Promise.all(promiseList);
        if (res1.code == "OK") {
          this.$refs.multipleTable.clearSelection();
          this.selColumnIds = [];
          // this.changeForm = {
          //   contactId: ""
          // };
          // this.$refs.changeForm.resetFields();

          this.getReviewServiceReq();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    handleClose(name) {
      this[name] = false;
    },
    closeTask() {
      this.$confirm("是否确认关闭回访单？", "关闭回访单", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.closeTaskReq();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //关闭工单
    async closeTaskReq() {
      try {
        let res = await closeServiceTask({
          serviceIds: [this.basicInfo.workId]
        });
        if (res.code == "OK") {
          this.$message.success("关闭成功");
          this.queryDetail();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  },
  created() {
    let hasParamsRoute = this.getHasParamsRoute();
    this.workNo = hasParamsRoute.params.workNo;
    if (this.workNo) {
      this.queryDetail();
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.custom-dialog {
  .el-dialog__body {
    max-height: 500px;
    overflow-y: auto;
    padding: 20px;
    .title {
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      & > div {
        width: 355px;
      }
      & > span {
        flex-shrink: 0;
        color: #e8a950;
      }
    }
    .required {
      position: relative;
      &::before {
        position: absolute;
        left: -10px;
        content: "*";
        color: #f56c6c;
        margin-right: 4px;
      }
    }
    .e6-vr-select {
      width: auto;
    }
    .radio-content {
      margin-bottom: 15px;
    }
    .textarea-content {
      margin-bottom: 15px;
    }
    .result-content {
      margin-bottom: 30px;
      .answer {
        margin-bottom: 10px;
      }
    }
    .el-input {
      width: 300px;
    }
  }
}
.main-wrapper .edit-wrapper .edit-wrapper__body {
  height: 100% !important;
}
</style>
